import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ImprintComponent} from './imprint/imprint.component';
import {MatIconModule} from '@angular/material/icon';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ContactPopupComponent} from './contact-popup/contact-popup.component';
import {PopupDialogComponent} from './contact-popup/popup-dialog/popup-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {HttpClientModule} from '@angular/common/http';
import {LandingComponent} from './landing/landing.component';
import {AboutComponent} from './about/about.component';
import {ClaimComponent} from './claim/claim.component';
import {OfferComponent} from './offer/offer.component';
import {FooterComponent} from './footer/footer.component';
import {ContactCompComponent} from './contact-comp/contact-comp.component';
import {ApplicationComponent} from './application/application.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    AppComponent,
    ImprintComponent,
    ContactPopupComponent,
    PopupDialogComponent,
    LandingComponent,
    AboutComponent,
    ClaimComponent,
    OfferComponent,
    FooterComponent,
    ContactCompComponent,
    ApplicationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    MatCheckboxModule,
    HttpClientModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
