
<div class="grid__container">
  <div class="grid__item">
      <div  data-aos="fade-right" data-aos-duration="1000" class="card__container">
        <h1>Werdegang</h1>
        <div class="card__item">
          <ul>
            <li>
              <div>
                Master of Science Informatik
                <br>
                <span>Karlsruher Institut für Technologie</span>
              </div>
            </li>
            <li>
              <div>
                Bachelor of Science Informatik
                <br>
                <span>Technische Universität Dortmund</span>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div  data-aos="fade-right" data-aos-duration="1000" class="card__container">
        <h1>Erfahrung</h1>
        <div class="card__item work">
          <ul>
            <li>
              <div>
                Green BPM Consultant
                <br>
                <span>envite consulting</span>
              </div>
            </li>
            <li>
              <div>
                Werkstudent in der Software-Entwicklung
                <br>
                <span>adesso SE</span>
              </div>
            </li>
            <li>
              <div>
                Werkstudent in der Software-Entwicklung
                <br>
                <span>Fraunhofer Institut für Materialfluss und Logistik</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
  </div>
  <div data-aos="zoom-in" data-aos-duration="2000" class="grid__item">
    <div  class="image__container" >
      <img src="assets/software-developer-ged4e79801_1920.jpg">
    </div>
  </div>
</div>
