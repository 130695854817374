<div class="call__part">
  <div  data-aos="fade-up" class="call__container">
    <h1>Interesse?</h1>
    <div class="break"></div>
    <p>Dann nehmen Sie Kontakt zu mir auf.</p>
    <div class="break"></div>
    <button class="sc_button" (click)="openInstagram()" ><fa-icon [icon]="icon_insta"></fa-icon></button>
    <button class="sc_button" (click)="openLinkedIn()"><fa-icon [icon]="icon_linkedIn"></fa-icon></button>
    <div class="break"></div>
    <button class="contactButton" (click)="openContactDialog()">Zum Kontaktformular <mat-icon>email</mat-icon></button>
  </div>
</div>
