import {Component, OnInit} from '@angular/core';
import {ContactPopupComponent} from "../contact-popup/contact-popup.component";
import {MatDialog} from '@angular/material/dialog';
import {faInstagram, faLinkedin} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {


  icon_insta = faInstagram;
  icon_linkedIn = faLinkedin;

  constructor(private dialog : MatDialog) { }

  ngOnInit(): void {
  }

  openContactDialog() {
    this.dialog.open(ContactPopupComponent, {panelClass: 'custom-dialog-container'});
  }

  openLinkedIn() {
    window.open('https://de.linkedin.com/in/ruessmann', '_blank');
  }

  openInstagram(){
    window.open('https://www.instagram.com/webdesign_ruessmann/', '_blank');
  }

}
