<div class="offer__part">
  <div data-aos="fade-down" class="offer__heading">
    <h1>Diese Leistungen bekommen Sie immer.</h1>
  </div>
  <div class="item__container">
    <div  class = "content__container" *ngFor="let offer of offerContent">
      <div data-aos="zoom-in" data-aos-duration="1000" class="item">
        <div class="image__container">
          <img *ngIf="offer.frontActive"  [@inOutAnimation] [src]=offer.imgSrc>
          <div *ngIf="!offer.frontActive" [@outInAnimation] >
            <p> <span>{{offer.heading}}</span>
              <p>{{offer.description}}</p>
          </div>
        </div>
        <div class="content" [ngClass]="{'flex__end' : !offer.frontActive}">
          <h1 [hidden]="!offer.frontActive"  [@inOutAnimation]>{{offer.heading}}</h1>
          <button  class="cardButton"
                   (click)="toggleFrontActive(offer.id)">
            <mat-icon  [ngClass]="{'rotate' : !offer.frontActive }" >add_circle</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row navigate">
    <button class="navButton" (click)="switchToLeft()"><mat-icon>navigate_before</mat-icon></button>
    <button class="navButton" (click)="switchToRight()"><mat-icon>navigate_next</mat-icon></button>
  </div>
</div>
