
<div class="grid__container">
  <div data-aos="zoom-in" data-aos-duration="2000" class="grid__item">
    <div class="image__container">
      <img src="assets/laptop-gbc1c59c71_1920.jpg">
    </div>
  </div>
  <div data-aos="fade-up" data-aos-duration="2000" class="grid__item" >
    <div class="background">
      <h1>Das ist mein Anspruch.</h1>
      <ul>
        <li>
          <div>
            Eigenständige Entwicklung
            <br>
            <span>Von Design über Implementierung</span>
          </div>
        </li>
        <li>
          <div>
            Grenzenlose Individualisierung
            <br>
            <span>100% modern und kreativ</span>
          </div>
        </li>
        <li>
          <div>
            Hervorragendes Ergebnis
            <br>
            <span>Websites, die einen Unterschied machen</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
