
<div class="grid__container">
  <div class="grid__picture">
    <div  data-aos="zoom-in"  class="image__container">
      <img src="assets/picture_self.jpg" class="portrait">
    </div>
    <div class="sc_container">
      <button class="sc_button" (click)="openInstagram()" ><fa-icon [icon]="icon_insta"></fa-icon></button>
      <button class="sc_button" (click)="openLinkedIn()"><fa-icon [icon]="icon_linkedIn"></fa-icon></button>
    </div>
  </div>
  <div class="grid__item">
    <div   class="landing__column">
      <h1 data-aos="fade-right" class="greeting">Hallo, ich bin Paul.
        <span>M. Sc. Informatik</span></h1>
      <div class="description_container">
        <p class="further_description" data-aos="fade-right">Durch konstante Weiterbildung erstelle ich ansprechende Websites und professionelle Software.</p>
      </div>
      <button class="c_button" data-aos="fade-right" (click)="openContactDialog()">Zur Kontaktaufnahme <mat-icon>email</mat-icon></button>
      <div data-aos="fade-down" data-aos-delay="500" data-aos-duration="1000" class="scroller">
      <p class="scroll-hint-container">
        Scroll down <mat-icon>mouse</mat-icon>
      </p>
      </div>
    </div>
  </div>
</div>
