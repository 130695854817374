import { Component, OnInit } from '@angular/core';
import {ContactPopupComponent} from '../contact-popup/contact-popup.component';
import {MatDialog} from '@angular/material/dialog';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'app-contact-comp',
  templateUrl: './contact-comp.component.html',
  styleUrls: ['./contact-comp.component.scss']
})
export class ContactCompComponent implements OnInit {

  icon_insta = faInstagram;
  icon_linkedIn = faLinkedin;

  constructor(private dialog: MatDialog, private router : Router) { }

  ngOnInit(): void {}

  openContactDialog() {
    this.dialog.open(ContactPopupComponent, {panelClass: 'custom-dialog-container'});
  }

  openLinkedIn() {
  window.open('https://de.linkedin.com/in/ruessmann', '_blank');
  }

  openInstagram(){
    window.open('https://www.instagram.com/webdesign_ruessmann/', '_blank');
  }
}
