<div class="footer__part">
  <div class="footer__container">
    <div *ngIf="isImprint">
      <h2>Navigation</h2>
      <a href="">Home</a>
    </div>
    <div *ngIf="!isImprint">
      <h2>Rechtliches</h2>
      <a href="/imprint">Datenschutz & Impressum</a>
    </div>
    <p> <mat-icon>copyright</mat-icon> 2023 Paul Rüssmann. Alle Rechte vorbehalten.</p>
  </div>
</div>
