export class Data {
  public static fullOffers: { id: number, frontActive: boolean , heading: string, description: string, imgSrc: string }[] = [
  { id: 0,
    frontActive : true,
    heading: 'Persönliche Beratung',
    description: 'Ich helfe Ihnen gerne, Ihren Webauftriff zu verbessern und präsent im Web zu werden.',
    imgSrc: 'assets/offer-consulting.jpg'},
  { id: 1,
    frontActive : true,
    heading: 'Individuelles Design',
    description: 'Jedes Design wird individuell auf den Kunden angepasst und zugeschnitten.',
    imgSrc: 'assets/offer-design.jpg'},
  { id: 2,
    frontActive : true,
    heading: 'Responsive Layout',
    description: 'Egal ob Computer, Laptop, Smartphone oder Tablet - Ihre Website sieht auf jeden Endgerät professionell aus!',
    imgSrc: 'assets/offer-responsive.jpg'},
    { id: 3,
      frontActive : true,
      heading: '100% Zufriedenheit',
      description: 'Projekte haben keine feste Laufzeit - zum Abschluss kommt es erst, wenn Sie mit dem Ergebnis zufrieden sind.',
      imgSrc: 'assets/offer-happy.webp'},
    { id: 4,
      frontActive : true,
      heading: 'DSGVO-Konformität',
      description: 'Jede Website erfüllt die gesetzlich vorgeschriebenen Datenschutzrichtlinien und weist entsprechende Angaben vor.',
      imgSrc: 'assets/offer-legal.jpg'},
    { id: 5,
      frontActive : true,
      heading: 'Weitere Verfügbarkeit',
      description: 'Nach Abschluss stehe ich für jegliche Änderungen, ob inhaltlich oder grafisch, selbstverständlich zur Verfügung.',
      imgSrc: 'assets/offer-fw.jpg'},
];

}
