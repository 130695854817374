import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {PopupDialogComponent} from "./popup-dialog/popup-dialog.component";

@Component({
  selector: 'app-contact-popup',
  templateUrl: './contact-popup.component.html',
  styleUrls: ['./contact-popup.component.scss']
})
export class ContactPopupComponent implements OnInit {

  rowHeight: any;
  numberOfCols;
  name: string;
  mail: string;
  header: string;
  message: string;
  privacyAccepted: boolean = false;
  test: any;
  captchaValue: number;
  firstCaptchaNumber: number
  secondCaptchaNumber: number
  captchaResult: number

  constructor(private http: HttpClient, private dialog: MatDialog) {
    this.rowHeight = window.innerHeight;
    this.numberOfCols = 1;

  }


  ngOnInit(): void {
    this.firstCaptchaNumber = this.randomIntFromInterval(1, 20);
    this.secondCaptchaNumber = this.randomIntFromInterval(1, 10);
    this.captchaResult = this.firstCaptchaNumber + this.secondCaptchaNumber;
  }

  private randomIntFromInterval(min: number, max: number) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  async onSendMail() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: 'Ihre Nachricht wurde erfolgreich versendet.'
    }

    if (!this.isCaptchaSolved()) {
      this.openWarningDialogForCaptcha();
      return;
    }
    this.http.post('https://formspree.io/f/mrgreoyz', {
      name: this.name,
      replyto: this.mail,
      message: this.message
    }).subscribe(() => {
      this.dialog.open(PopupDialogComponent, dialogConfig).afterOpened().toPromise().then(async () => {
        await this.delay(3000)
          .then(() => this.dialog.closeAll());
      });
    });
  }

  changePrivacyAccepted() {
    this.privacyAccepted = !this.privacyAccepted;
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  getCaptchaPlaceholder() {
    return 'Captcha: ' + this.firstCaptchaNumber + ' + ' + this.secondCaptchaNumber + ' = '
  }

  public isCaptchaSolved() {
    return this.captchaResult == this.captchaValue;
  }

  private openWarningDialogForCaptcha() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      message: 'Inkorrektes Captcha!',
      id: 'popup'
    }
    this.dialog.open(PopupDialogComponent, dialogConfig).afterOpened().toPromise().then(async () => {
      await this.delay(3000)
        .then(() => this.dialog.closeAll() );
    });
  }
}

