import {Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.5s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'outInAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('2.5s ease-in',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})


export class AppComponent implements OnInit {
  title = 'Paul Rüssmann | Website';


  public constructor(private titleService : Title) {
  }

   ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }



}
