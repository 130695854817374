import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input()
  isImprint: boolean;

  icon_insta = faInstagram;
  icon_linkedIn = faLinkedin;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
