<div class="root">

  <app-landing></app-landing>

  <app-about></app-about>

  <app-offer></app-offer>

  <app-claim></app-claim>

  <app-contact-comp></app-contact-comp>

  <app-footer [isImprint]="false"></app-footer>

</div>

