import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ImprintComponent} from './imprint/imprint.component';
import {ApplicationComponent} from './application/application.component';


const routes: Routes = [
  {path: '*' , redirectTo: ''},
  { path: 'imprint', component: ImprintComponent},
  {path: '', component: ApplicationComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
