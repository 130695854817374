<div class="application">
  <div class="content">
    <div id="privacy">
      <h1>Datenschutzerklärung</h1>
      <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können.
        Ausführliche Informationen zum Thema Datenschutz entnehmen Sie der unter diesem Text aufgeführten Datenschutzerklärung.
      </p>
      <h2>Allgemeine Hinweise</h2>
      <p>
        Der Betreiber dieser Seite nimmt den Schutz Ihrer persönlichen Daten sehr ernst.
        Ihre personenbezogenen Daten werden vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung behandelt.
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
      </p>
      <p>
        Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
        Es wird darauf hingewiesen, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
      </p>
      <h2>Kontaktformular</h2>
      <p>
        Diese Website nutzt <a href="https://formspree.io/">Formspree</a>. Um Ihnen die Funktionalität des Kontaktformulars zur Verfügung zu stellen, werden Ihre eingegebenen Kontaktdaten an Formspree gesendet. Diese Daten werden dort verarbeitet, gespeichert und per E-Mail an den Betreiber dieser Website weitergegeben.
        Außerdem werden von Formspree unter anderem weitere Daten wie Ihre IP-Adresse, Ihr Typ des Browsers, die Domain der Website, das Datum und die Zeit des Zugriffs erhoben, um die gewünschte Funktionalität des Kontaktformulars bereitzustellen.
        Rechtsgrundlage für die Nutzung von Formspree ist Art. 6 Abs. 1 S. 1 lit. f DSGVO (berechtigtes Interesse). Es werden personenbezogene Daten in die USA übertragen. Formspree ist Teil des Shield-Abkommens, die Datenverarbeitung ist also DSGVO-konform.
      </p>
      <p>
        Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an den Betreiber dieser Website. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.

        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben beim Betreiber der Website, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <h2>Server-Log-Dateien</h2>
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Diese sind:
      </p>
      <ul>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>IP-Adresse</li>
      </ul>
      <p>
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.

        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
      </p>
      <h2>Cookies</h2>
      <p>
        Diese Website benutzt Firebase Hosting. Dadurch werden automatisch Cookies für Authentifizierungsprozesse bereitgestellt. Diese Cookies sammeln keine personenbezogenen Daten, daher erfordert die Nutzung auch keiner Zustimmung Ihrerseits. Die Website benutzt keine Google Analystics.
      </p>
      <h2>Hinweis zur verantwortlichen Stelle</h2>
      <p>
        Die Angaben zur verantwortlichen Stelle für die Datenverarbeitung dieser Website können Sie dem folgenden Impressum entnehmen.
      </p>
      <br>
      <div>
        <h1>Impressum</h1>
        <h2>Pflichtangaben</h2>
        <p>Paul Rüssmann
          <br>
          Niederhofer Kohlenweg 235a
          <br>44265 Dortmund
        </p>
        <h2>Kontakt</h2>
        <p>
          E-Mail: paul@ruessmann.net
        <p>
          Mobil: +4917676743726
        </p>
        <h2>Angaben zum Urheberrecht</h2>
        <p>
          Die durch den Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          <br>
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, wird um einen entsprechenden Hinweis gebeten. Bei Bekanntwerden von Rechtsverletzungen werden derartige Inhalte umgehend entfernt.
        </p>
      </div>
    </div>
  </div>
  <app-footer [isImprint]="true"></app-footer>
  </div>

