<div class = "contact__container">
  <h1>Kontaktformular</h1>
  <form   (ngSubmit)="onSendMail()" #contactControl = "ngForm">
    <br>
    <p>Bitte beachten Sie, dass Sie alle Felder ausfüllen
      und der Datenschutzerklärung zustimmen müssen,
      um das Formular abschicken zu können.</p>
    <br>
    <label for="name" class="zero">Name</label>
    <br>
    <input required
           type ="text"
           class="mat-form-field-can-float"
           id="name"
           name="name"
           placeholder=""
           [(ngModel)]="name">
    <br>
    <br>
    <label for="mail">Email</label>
    <br>
    <input type ="text"
           required
           class="mat-form-field-can-float"
           id="mail"
           name="mail"
           placeholder=""
           [(ngModel)]="mail">
    <br>
    <br>
    <label for="message">Nachricht</label>
    <br>
    <textarea type ="text" required
              class="mat-form-field-can-float messageInput"
              id="message"
              name="message"
              placeholder="" [(ngModel)]="message">
          </textarea>
    <br>
    <br>
    <input type ="text"
           required
           [placeholder]="this.getCaptchaPlaceholder()"
           class="mat-form-field-can-float"
           id="captcha"
           name="captcha"
           placeholder=""
           [(ngModel)]="captchaValue">
    <br>
    <br>
    <div class="privacy__container">
    <mat-checkbox [value]="privacyAccepted" (change)="changePrivacyAccepted()"></mat-checkbox>
      <p>Ich habe die <a href="/imprint">Datenschutzerklärung</a> gelesen und stimme dieser zu. </p>
    </div>
         <br>
    <div class="container__center">
      <button  type = "submit" class="sendButton" [disabled]="contactControl.invalid || !privacyAccepted || !this.isCaptchaSolved()" class="sendButton">Abschicken <mat-icon>send</mat-icon></button>
    </div>
  </form>
</div>
