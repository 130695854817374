import {Component, HostListener, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Data} from 'src/app/Data';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.5s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.5s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'outInAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('2.5s ease-in',
              style({ opacity: 1 }))
          ]
        )
      ]
    )
  ]
})
export class OfferComponent implements OnInit {

  public offerContent: { id: number, frontActive: boolean, heading: string; description: string; imgSrc: string }[];
  public fullOffers: { id: number, frontActive: boolean, heading: string; description: string; imgSrc: string }[];
  public currentPositions: number[];

  public constructor(private dialog: MatDialog) {
    this.offerContent = this.calculateDisplayedCardsOnScreenSize(window.innerWidth);
    this.fullOffers = Data.fullOffers;
    this.currentPositions = [0, 1, 2, 3];
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
   this.offerContent =  this.calculateDisplayedCardsOnScreenSize(event.target.innerWidth);
  }

  ngOnInit(): void {
  }

  toggleFrontActive(offerId: number){
    const offer = this.offerContent.find(candidate => candidate.id === offerId);
    offer.frontActive = !offer.frontActive;
  }

  switchToRight(){
    this.increaseCurrentPositions();
    this.createNewOfferContent();
  }

  switchToLeft(){
    this.decreaseCurrentPositions();
    this.createNewOfferContent();
  }


  private increaseCurrentPositions() {
    for (let i = 0; i < this.currentPositions.length; i++){
      let value = this.currentPositions[i];
      this.currentPositions[i] = value === 5 ? 0 : ++value;
    }
  }

  private createNewOfferContent() {
    for (let i = 0; i < this.offerContent.length; i++){
      this.offerContent[i] = this.fullOffers[this.currentPositions[i]];
    }
  }

  private decreaseCurrentPositions() {
    for (let i = 0; i < this.currentPositions.length; i++){
      let value = this.currentPositions[i];
      this.currentPositions[i] = value === 0 ? 5 : --value;
    }
  }

  private calculateDisplayedCardsOnScreenSize(screenWidth : number) {
    let targetIndex = 0;
    if(screenWidth >= 1500) targetIndex = 4;
    if(screenWidth < 1500) targetIndex = 3;
    if(screenWidth < 1000) targetIndex = 2;
    if(screenWidth < 650) targetIndex = 1;
    return Data.fullOffers.filter(x => Data.fullOffers.indexOf(x) < targetIndex);
  }

}
